import React from 'react';
import axios from 'axios';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { log } from 'kn-react';

const mapboxAccessToken = 'pk.eyJ1Ijoic3Bjb3R0ZXIta2luZXJ0aWEiLCJhIjoiY2pxbnNtdHlnN204ZzQzcGRnZW40OWttdyJ9.ALLOkv9kQ-VyC1BMtNs4QQ';

class LocationSearch extends React.Component {
  state = {
    isLoading: false,
    options: [],
  }

  searchLocations = term => {
    this.setState({ isLoading: true });
    const escapedTerm = this.escapeTerm(term)
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${ escapedTerm }.json`;
    return axios.get(
      url,
      {
        params: {
          access_token: mapboxAccessToken,
          types: 'address,neighborhood,locality,place,district,postcode,region',
        }
    })
    .then(response => {
      log(response.data);
      this.setState({ options: response.data.features, isLoading: false });
    })
  }

  escapeTerm = term => {
    return encodeURIComponent(term);
  }


  onChange = selectedOptions => {
    const selected = selectedOptions[0];
    if(selected) {
      this.props.onLocationSelect(selected);
    }
  }


  render(){
    return (
      <React.Fragment>
        <AsyncTypeahead
          id="place-name-location-search"
          isLoading={this.state.isLoading}
          options={this.state.options}
          filterBy={() => true}
          minLength={3}
          onChange={this.onChange}
          onSearch={this.searchLocations}
          promptText="Search for a location..."
          labelKey="place_name"
          useCache={false}
        />
      </React.Fragment>
    );
  }

}

LocationSearch.defaultProps = {
  onLocationSelect: () => {}
}

export default LocationSearch;