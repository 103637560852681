import React, { Component } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import axios from 'axios';

import history from './history';
import AuthProvider from './Auth/AuthProvider';
import Auth from './Auth/Auth';
import Logout from './Auth/Logout';
import Home from './Home/Home';


axios.interceptors.response.use(undefined, error => {
  console.log('error', Object.keys(error));
  return new Promise((resolve, reject) => {
    if(error.response.status === 403){
      history.push('/logout');
      return Promise.reject(error);
    }
    throw error;
  });
});

class App extends Component {

  constructor(props){
    super(props);
    this.redirectTotHttps();
  }

  redirectTotHttps = () => {
    const { href } = window.location;
    const isHttps = href.startsWith('https');
    const isLocalhost = href.includes('://localhost:');

    if (!isLocalhost && !isHttps) {
      window.location.href = href.replace('http://', 'https://');
      return;
    }
  }

  render() {

    return (
      <AuthProvider>
        <Router history={history} basename={process.env.PUBLIC_URL}>
          <Switch>
            <Route exact path="/auth" component={Auth}/>
            <Route exact path="/logout" component={Logout}/>
            <Route exact path="/" component={Home}/>
          </Switch>
        </Router>
      </AuthProvider>
    );
  }

}

export default App;
