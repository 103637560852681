import React from 'react';
import axios from 'axios';
import { Alert } from 'react-bootstrap';
import { withContext, log, MaxWidth } from 'kn-react';
import { AuthContext } from './AuthProvider';
import history from '../history';
import GoogleLogin from './GoogleLogin';



class Auth extends React.Component {
  state = {
    googleClientId: '',
    error: ''
  }

  componentDidMount = () => {
    if(this.props.hasToken()) {
      history.push('/');
    }


    return this.fetchClientId();
  }



  fetchClientId = () => {
    return axios.post(
      '/wells/auth/token/client'
    ).then(response => {
      this.setState({
        googleClientId: response.data.clientId
      })
    });
  }



  authCallback = response => {
    return axios.post(
      '/wells/auth/token',
      { token: response.credential }
    ).then(() => {
      this.props.setToken( response.credential );
      history.push('/');
    }).catch(() => this.setState(
      { error: 'Invalid login credentials.' },
      this.props.removeToken
    ));
  }



  render(){
    const { error } = this.state;

    return (
      <div style={{ marginTop: 50, marginBottom: 25, textAlign: 'center' }}>
        <MaxWidth maxWidth={300}>
          <img src="/allied_logo.png" style={{ marginBottom: 20 }}/>

          {
            error ?
              <Alert variant="warning">{ error }</Alert>
            : null
          }

          {
            !this.state.googleClientId ?
              <i className="fas fa-spinner fa-spin"/>
            :
              <GoogleLogin
                clientId={this.state.googleClientId}
                buttonText="Please Login"
                authCallback={this.authCallback}
              />
          }
        </MaxWidth>
      </div>
    );
  }

}

export default withContext(AuthContext, Auth);