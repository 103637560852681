import React from 'react';
import L from 'leaflet';
import axios from 'axios';
import * as d3 from 'd3';
import Supercluster from 'supercluster';
import {Alert} from 'react-bootstrap';
import { Map, TileLayer, Marker, Polyline, Tooltip } from 'react-leaflet';
import { log } from 'kn-react';

import history from '../history';
import './WellMap.css';

const renderer = L.canvas();

class WellMap extends React.PureComponent {

  state = {
    position: null,
  }

  componentDidMount = () => {
    this.getPosition();
    log('mapRef', this.map);

    this.clusterLayer = new L.LayerGroup();
    this.clusterLayer.addTo(this.map);
    window.clusterLayer = this.clusterLayer;
  }



  flyToBounds = (bounds) => {
    this.map.fitBounds(new L.LatLngBounds(bounds), { maxZoom: 16 });
  }



  flyTo = (lat, lng) => {
    this.map.setView({ lat, lng }, 16);
    // this.map.setZoom(16);
  }

  componentWillReceiveProps = () => {
    if(this.map.zoom < 12) {
      this.countyCounts();
    } else {
    }
  }

  getPosition = () => {
    if (!'geolocation' in navigator) return;
    navigator.geolocation.getCurrentPosition(position => {
      const p = [position.coords.latitude, position.coords.longitude];
      this.setState({ position: p });
    });
  }

  bindMapEvents = mapRef => {
    if(this.map) return;

    log('bindMapEvents', mapRef);
    this.map = mapRef.leafletElement;
    window.map = this.map;
    this.map.on('zoomend', this.onMapChange);
    this.map.on('moveend', this.onMapChange);
    this.onMapChange();
  }


  onMapChange = () => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout( () => {
      this.props.onMapChange(this.map);
    }, 600);
  }



  onClick = location => {
    if(window.location.pathname.indexOf(`/${ location }`) > -1){
      history.push('/');
    } else {
      history.push(`/locations/${ location }`);
    }
  }

  clusterWells = () => {
    const mapBounds = this.map.getBounds();
    const superclusterBounds = [
      mapBounds._southWest.lng,
      mapBounds._southWest.lat,
      mapBounds._northEast.lng,
      mapBounds._northEast.lat,
    ];


    const wellsGeojson = this.props.wells.map(w => ({
      type: 'Feature',
      geometry: w.geojson,
      properties: w,
    }));


    log('wellsGeojson', wellsGeojson);
    this.clusterIndex = new Supercluster({ radius: 80, maxZoom: 19 }).load(wellsGeojson);
    log('clusterIndex', this.clusterIndex);
    const clusters = this.clusterIndex.getClusters(superclusterBounds, this.map.getZoom());
    log('clusters', clusters);

    return clusters;
  }



  clusters = () => {
    if(!this.clusterLayer) {
      return;
    }

    let clusters = [];
    try {
      clusters = this.clusterWells();
    }
    catch (e) {
      console.log(`Error retrieving clusters:\n${e}`);
    }

    this.clusterLayer.clearLayers();


    const maxClusterSize = d3.max(clusters, c => c.properties.point_count);
    const radiusScale = d3.scalePow(0.5).domain([0, maxClusterSize]).range([4, 15]);
    log('maxClusterSize', maxClusterSize);


    clusters.forEach(c => {
      const radius = radiusScale(c.properties.point_count);

      if(isNaN(radius)){
        return;
      }

      const { coordinates } = c.geometry;
      const center = L.latLng(coordinates[1], coordinates[0]);

      const circleMarker = L.circleMarker(
        center,
        {
          renderer,
          radius,
        }
      )
      .on('click', e => this.onClickCluster(e, c))
      .addTo(this.clusterLayer);
    });

  }


  onClickCluster = (e, cluster) => {
    this.clusterLayer.eachLayer(l => l.setStyle({ color: '#3388ff' }));
    e.target.setStyle({ color: 'red' })
    window.circleMarker = e.target;


    const leaves = this.clusterIndex.getLeaves(cluster.id, Infinity);
    this.props.onClickCluster({ cluster, leaves });
  }



  clusterStyle = feature => {


    return {
      fillColor: this.clusterColor(feature.properties.wellCount),
      // color: '#ccc',
      fillOpacity: 0.8,
      weight: 1,
    };
  }



  defaultBounds = new L.LatLngBounds([
    [44.0509226202137, -73.21456432342531],
    [36.477447032090296, -81.65206432342531]
  ])


  render(){
    const { mapStyle } = this.props;

    return (
      <div className="WellMap">
        <Map
          // maxZoom={19}
          renderer={renderer}
          bounds={this.defaultBounds}
          ref={ref => ref && this.bindMapEvents(ref)}
          style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
        >



          { mapStyle === 'default' ?
            <>
              <TileLayer
                attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                url="https://stamen-tiles-{s}.a.ssl.fastly.net/toposm-color-relief/{z}/{x}/{y}.jpg"
                maxZoom={19}
                subdomains="abcd"
                opacity={0.75}
              />
                <TileLayer
                  attribution="&copy; <a href='http/://cartodb.com/attributions'>CartoDB</a>"
                  url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
                  maxZoom={19}
                  subdomains="abcd"
                  opacity={0.45}
                />
                <TileLayer
                  attribution="&copy; <a href='http/://cartodb.com/attributions'>CartoDB</a>"
                  url="https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}{r}.png"
                  maxZoom={19}
                  subdomains="abcd"
                  opacity={1}
                />
            </>
            : null
          }

         { mapStyle === 'satellite' ?
            <>
              <TileLayer
                attribution="&copy; <a href='http/://cartodb.com/attributions'>CartoDB</a>"
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                maxZoom={19}
                opacity={1}
              />
              <TileLayer
                attribution="&copy; <a href='http/://cartodb.com/attributions'>CartoDB</a>"
                url="https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}{r}.png"
                maxZoom={19}
                subdomains="abcd"
                opacity={1}
              />

            </>
          : null }


          { mapStyle === 'streets' ?
            <TileLayer
              attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              // url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"

              maxZoom={19}
              opacity={1}
            />
          : null }

          { this.clusters() }



        </Map>
      </div>
    );
  }

}

WellMap.defaultProps = {
  onMapChange: () => {},
  satellite: false,
};


export default WellMap;