import React from 'react';
import axios from 'axios';
import { withContext, log, format } from 'kn-react';
import { FormGroup, Radio } from 'react-bootstrap';

import AuthGuard from '../Auth/AuthGuard';
import { AuthContext } from '../Auth/AuthProvider';
import AppNav from '../AppNav/AppNav';
import Layout from '../Layout/Layout';
import ClusterList from '../ClusterList/ClusterList';
import WellMap from '../WellMap/WellMap';
import LocationSearch from '../LocationSearch/LocationSearch';

import './Home.css';

class Home extends React.Component {
  state = {
    zoom: null,
    bounds: null,
    wells: [],
    counties: [],
    loading: false,
    hasError: false,
    selectedCluster: null,
    mapStyle: 'default'
  }


  onMapChange = map => {
    const zoom = map.getZoom();
    const bounds = map.getBounds();

    // this.loadCountyCounts(bounds);
      if (zoom >= 10) {
      this.loadWells(bounds);
    }
    this.setState({ zoom, bounds, selectedCluster: null });
  }


  onLocationSelect = location => {
    log('onLocationSelect', location);
    log('this.wellMap', this.wellMap);

    const { bbox, center } = location;
    if(bbox){
      log('Flying to bbox', bbox);
      this.wellMap.flyToBounds([ [bbox[1], bbox[0]], [bbox[3], bbox[2]] ]);
      // [[location.LatMin, location.LonMin], [location.LatMax, location.LonMax]]
    } else {
      log('Flying to center', center);
      this.wellMap.flyTo(center[1], center[0]);
    }
    log('this.wellMap after flyto', this.wellMap);
  }


  loadWells = bounds => {
    log('loadWells', bounds);
    this.setState(
      {
        loading: true,
        hasError: false
      },
      () => this.fetchWells(
        bounds
      ).then(wells => this.setState({
        wells,
        loading: false
      })).catch(() => this.setState({
        hasError: true
      }))
    );
  }


  fetchWells = bounds => {
    return axios.get(
      '/wells/in_bounds',
      {
        params: {
          southWestLat: bounds._southWest.lat,
          southWestLng: bounds._southWest.lng,
          northEastLat: bounds._northEast.lat,
          northEastLng: bounds._northEast.lng,
        },
        headers: this.props.getAuthHeaders()
      }
    ).then(response => {
      log('fetchWells response', response);

      return response.data.map(d => {
        const drilledDate = new Date(d.drilledDate);
        return {
          ...d,
          drilledDate: drilledDate instanceof Date && !isNaN(drilledDate) ? drilledDate : null,
        };
      });
    })
  }


  onClickCluster = selectedCluster => {
    log(selectedCluster);
    this.setState({ selectedCluster });
  }


  loading = () => (
    this.state.hasError ?
      <React.Fragment>
        There was an error. Please try again.
      </React.Fragment>
    :
      <React.Fragment>
        <i className="fas fa-spinner fa-spin"/> Loading...
      </React.Fragment>
  )



  zoomIn = () => (
    <React.Fragment>
      <div>Zoom-in closer or search for a location.</div>
    </React.Fragment>
  )


  sidebar = () => {
    const { zoom, loading, selectedCluster, wells } = this.state;
    return (
      <div className="message">
        { zoom < 10 ? this.zoomIn() : null }
        { loading && zoom >= 10 ? this.loading() : null }
        { zoom >= 10 && !loading && !selectedCluster ? `${format.commas(wells.length)} well records found.` : null }
      </div>
    );
  }

  render(){
    const { wells, counties, loading, selectedCluster, mapStyle } = this.state;
    const radioClassName = mapStyle === 'satellite'  ? 'TextShadow' : undefined

    return (
      <AuthGuard>
        <AppNav>
          <Layout
            left={
              <React.Fragment>
                <WellMap
                  ref={ ref => this.wellMap = ref }
                  onMapChange={this.onMapChange}
                  counties={counties}
                  wells={wells}
                  loading={loading}
                  onClickCluster={this.onClickCluster}
                  mapStyle={mapStyle}
                />
                <div className="LocationSearch">
                  <LocationSearch onLocationSelect={this.onLocationSelect}/>
                  <FormGroup>
                    <Radio name="radioGroup" inline onChange={e => this.setState({ mapStyle: 'default' }) } checked={mapStyle === 'default'} className={ radioClassName }>
                      Default
                    </Radio>{' '}
                    <Radio name="radioGroup" inline value onChange={e => this.setState({ mapStyle: 'satellite' }) } checked={mapStyle === 'satellite'} className={ radioClassName }>
                      Satellite
                    </Radio>
                    <Radio name="radioGroup" inline value onChange={e => this.setState({ mapStyle: 'streets' }) } checked={mapStyle === 'streets'} className={  radioClassName }>
                      Streets
                    </Radio>
                  </FormGroup>
                </div>
              </React.Fragment>
            }
            right={
              selectedCluster ?
                <ClusterList cluster={selectedCluster}/>
              :
                this.sidebar()
            }
          />

        </AppNav>
      </AuthGuard>
    );
  }

}

export default withContext(AuthContext, Home);