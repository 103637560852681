import React from 'react';
import { log } from 'kn-react';

const getStoredToken = () => {
  return localStorage.getItem('token') || null;
};

const storedToken = getStoredToken() || null;

const DEFAULT_STATE = {
  token: storedToken || null,
  loading: true,
};


export const AuthContext = React.createContext(DEFAULT_STATE);

export default class AuthProvider extends React.Component {
  state = DEFAULT_STATE;

  setToken = token => {
    log('setToken', token);
    this.setState({ token });
    localStorage.setItem('token', token);
  }

  removeToken = () => {
    log('removeToken');
    this.setState({ token: null });
    localStorage.removeItem('token');
  }

  getAuthHeaders = () => {
    return { 'Authorization': `Bearer ${ this.state.token }` };
  }


  hasToken = () => {
    const { token } = this.state;
    return token;
  }


  render(){
    return (
      <AuthContext.Provider
        value={{
          ...this.state,
          setToken: this.setToken,
          removeToken: this.removeToken,
          getAuthHeaders: this.getAuthHeaders,
          hasToken: this.hasToken,
        }}
      >
        { this.props.children }
      </AuthContext.Provider>
    );
  }
}
