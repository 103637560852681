import React from 'react';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import { format } from 'kn-react';
import './AppNav.css';

const waterWellCount = 1232719;


class AppNav extends React.Component {

  render(){
    const { children } = this.props;

    return (
      <React.Fragment>
        <Navbar fluid fixed="top">
          <Navbar.Header>
            <Navbar.Brand href="#" style={{ paddingTop: 5 }}>
              <img
                alt=""
                src="/allied_logo.png"
                className="d-inline-block align-top"
              />
            </Navbar.Brand>

            <Navbar.Toggle/>

          </Navbar.Header>

          <Navbar.Collapse>
            <Nav>
              <NavItem href="http://www.alliedwells.com/map-help/" target="_blank">Instructions</NavItem>
              <NavItem href="https://docs.google.com/spreadsheets/d/1f0g5JER94jZ56diUzyaZtTytrormYNlBiw32156_WCw/edit?usp=sharing" target="_blank">Map Data</NavItem>
            </Nav>
            <Navbar.Text pullRight className="infoText">
              <span className="recordCount">
                { format.commas(waterWellCount) } Records in MD, NY, PA
              </span>
              <br/>
              <span className="kinertia">Built by <a href="http://www.kinertia.com" target="_blank">Kinertia</a></span>
            </Navbar.Text>
          </Navbar.Collapse>
        </Navbar>
        <div className="AppBody">
          { children || [] }
        </div>
      </React.Fragment>
    );
  }

}

export default AppNav;