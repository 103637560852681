import React from 'react';
import { Redirect } from 'react-router-dom';
import { withContext } from 'kn-react';
import { AuthContext } from './AuthProvider';

class Logout extends React.Component {

  componentDidMount = () => {
    this.props.removeToken();
  }

  render(){
    return <Redirect to="/auth"/>;
  }

}

export default withContext(AuthContext, Logout);