import React from 'react';
import { descending } from 'd3';
import { format } from 'kn-react';
import './ClusterList.css';

export default props => {
  const { cluster } = props;

  let maxDepth = 0;
  cluster.leaves.forEach(w => {
    if(w.properties.depth > maxDepth) {
      maxDepth = w.properties.depth;
    }
  });

  cluster.leaves.sort((a,b) => descending(a.properties.drilledDate, b.properties.drilledDate));
  // console.log('sortedClusterLeaves', sortedClusterLeaves);


  setTimeout(scrollToTop, 100);

  return (

    <div id="ClusterList" className="ClusterList">
      <div className="ClusterSummary">
        { format.commas(cluster.cluster.properties.point_count) } Well Records
      </div>
      {
        cluster.leaves.map((w,j) => {
          let displayName = (w.properties.addressOrRoad || '') + ' ' + (w.properties.drillerName || '');
          displayName = displayName.trim();
          displayName = displayName || 'Unknown address and driller';

          const link =  w.properties.link ? <a className="link" target="_blank" href={w.properties.link}>{displayName}</a> : <div className="nolink">{displayName}</div>;
          const depthPercent = (w.properties.depth / maxDepth) * 100 + '%'
          const depth = w.properties.depth ? w.properties.depth + ' ft' : 'Unknown'

          return (
            <div className="ClusterListItem" key={j}>
              <div className="clearfix">
                {link}
                <div className="pull-right drilledDate">
                  { format.date(w.properties.drilledDate) }
                </div>
                <div className="stateIdentifier">
                  State ID: { w.properties.stateIdentifier }
                </div>

                <div className="depthInFeet clearfix">
                  Depth: { depth }
                </div>

                <div className="depthWrapper clearfix">
                  <div className="depthBar" style={{ width: depthPercent }}/>
                </div>
              </div>
            </div>
          );
        })
      }
    </div>
  )

};


const scrollToTop = () => {
  try {
    if(window.innerWidth >= 960) {
      document.getElementsByClassName("ClusterSummary")[0].scrollIntoView()
    }
  }
  catch(e) {
    // It didn't work
  }
}
