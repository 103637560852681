/*global google*/
import React from 'react';



const GOOGLE_LOG_IN_DIV_ID = 'google-login-button-id';

class GoogleLogin extends React.Component {
  componentDidMount = () => {
    return this.mountClientLibrary();
  }



  mountClientLibrary = () => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';


    document.body.appendChild( script );

    return this.mountSignInButton();
  }



  mountSignInButton = () => {
    if( ['undefined', 'null'].includes( typeof google ) ){
      return setTimeout(
        this.mountSignInButton,
        250
      );
    }


    google.accounts.id.initialize({
      client_id: this.props.clientId,
      callback: this.props.authCallback
    });

    google.accounts.id.renderButton(
      document.getElementById( GOOGLE_LOG_IN_DIV_ID ),
      {
        theme: "outline",
        size: "large",
        width: 300
      }
    );

    google.accounts.id.prompt();
  }



  render(){
    return (
      <div id={GOOGLE_LOG_IN_DIV_ID} />
    );
  }
}



GoogleLogin.defaultProps = {
  clientId: "",
  buttonText: "Please Login",
  authCallback: () => {}
}



export default GoogleLogin