import React from 'react';
import './Layout.css';

export default props => {
  return (
    <React.Fragment>
      <div className="Layout-left">
        {props.left}
      </div>
      <div className="Layout-right">
        {props.right}
      </div>
    </React.Fragment>
  )
}
