import React from 'react';
import { Redirect } from 'react-router-dom';
import { withContext } from 'kn-react';
import { AuthContext } from './AuthProvider';

const authGuard = props => {
  return props.hasToken() ? props.children : <Redirect to="/auth"/>;
}

export default withContext(AuthContext, authGuard);
